import React, {useEffect, useState} from 'react';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Section from '../../components/section';
import PricingPackeges from '../../components/pricing/pricingPackeges';

import {useQuery} from '@apollo/react-hooks';
import {GET_HEXOMATIC_USER} from '../../graphql/queries';
import TrustedSection from '../../components/trusted-section';
import {navigate} from 'gatsby';

const PromoGoldAnnual = ({pageContext}: any) => {
  const [annual, setAnnual] = useState(false);
  const ltd = false;
  const [promo, setPromo] = useState(false);
  const [showLtdText, setShowLtdText] = useState(false);
  const [leftSeats, setLeftSeats] = useState(0);
  const [firstName, setFirstName] = useState('');

  const {data: hexomaticUserInfo, refetch} = useQuery(GET_HEXOMATIC_USER, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    navigate('/pricing/');
  }, []);
  useEffect(() => {
    if (hexomaticUserInfo?.User?.get?.email && !hexomaticUserInfo?.User?.get?.promoData?.hexomatic_ltd) {
      navigate('/pricing/');
    }
  }, [hexomaticUserInfo]);

  useEffect(() => {
    const packageHexomatic = hexomaticUserInfo?.HexomaticUser?.getByLegacyId?.pricing_package;
    const packageHexomaticInterval = hexomaticUserInfo?.HexomaticUser?.getByLegacyId?.pricing_package_interval;
    const promoData = hexomaticUserInfo?.User?.get?.promoData?.hexomatic_ltd;
    if (promoData && packageHexomatic && (packageHexomatic === 'FREE' || packageHexomaticInterval === 'LTD')) {
    } else if (!promoData && (packageHexomaticInterval === 'ANNUAL' || packageHexomatic === 'GOLD')) {
      setAnnual(true);
    } else {
      setShowLtdText(false);
    }
    if (hexomaticUserInfo?.User?.get?.firstName) {
      setFirstName(hexomaticUserInfo.User.get.firstName);
    }
  }, [hexomaticUserInfo]);
  

  return (
    <Layout pricingPage>
      <PageHero
        title={`${firstName || ''}${firstName ? "'s" : ''} Private Promo`}
        subtitleEl={
          <strong style={{fontWeight: 500}}>
            <p className="m-0 mb-2">Get grandfathered to our Gold plan for the price of Silver (saving $400 a year!)</p>
          </strong>
        }
        noPaddingBottom
        titleSmall
        className="pricing-page"
        // subtitle2="Ahead of the launch, we would like to invite you today to be one of the very first to get access to Hexomatic and secure a very special early bird pre-launch lifetime deal.
        // Because this is our most ambitious idea to date, we want to soft launch first with a small cohort of our own paid customers first before opening the doors to the public later in the year.
        // This will enable us to prioritize our last mile development based on your business needs, feedback and suggestions."
        // subtitle3={
        //   <p>So here is the plan: We are opening <strong>3000 pre-launch seats</strong> for existing paid customers only. As usual, we always offer our best pricing to our own customers first so this is your chance to secure our legendary pre-launch lifetime deal at a fraction of the cost.</p>
        // }
        // subtitle4={
        //   <p>The offer ends on <strong>Thursday the 22nd at midnight</strong> (or when we reach the 3000 seats allocation). Pre-launch customers will then get first access to Hexomatic on the May 22nd with a public launch planned later this year at a higher price point.</p>
        // }
        // subtitle5='Your purchase will be covered by a 30 day refund period starting from the date of the soft launch planned on the May 22nd.'
        // countdown
        // leftSeats={leftSeats}
      />

      {/* <Section className="pricing-section">
        <div className="pricing-section pricing-section-promo m-auto" id="deal">
          <PricingPackeges
            interval="annual"
            products={pageContext.products}
            promo={promo}
            promoPage={showLtdText}
            goldSpecial
          />
        </div>
      </Section> */}
      {/* <PremiumCredits /> */}
      <TrustedSection />
    </Layout>
  );
};

export default PromoGoldAnnual;
